import React from 'react';
import IconBase from 'react-icon-base';

export default function Icon(props: any)  {
  return (
    <IconBase
      {...props}
      viewBox={"0 0 48.16 48.16"}
      dangerouslySetInnerHTML={{
        __html: `<path d="M24.08,0A24.08,24.08,0,1,0,48.16,24.08,24.08,24.08,0,0,0,24.08,0ZM11.88,24.89l.11-.16,6.26-9.8a.22.22,0,0,1,.38,0c1,2.35,1.95,5.27,1.52,7.08a11,11,0,0,1-1.23,2.7l-.23.4a.21.21,0,0,1-.18.09H12.06A.21.21,0,0,1,11.88,24.89Zm27.93,3.37a.24.24,0,0,1-.14.21,7.64,7.64,0,0,0-2.83,1.93c-1.77,2.45-3.11,6-6.12,6H18.17a8.07,8.07,0,0,1-8.06-8.08v-.14a.21.21,0,0,1,.22-.21h7a.22.22,0,0,1,.22.26,2.49,2.49,0,0,0,.25,1.34A2.46,2.46,0,0,0,20,30.89h3.47v-2.7H20.05a.23.23,0,0,1-.18-.35l.13-.18c.32-.46.78-1.18,1.24-2A15.4,15.4,0,0,0,22.11,24c.05-.11.09-.22.13-.33s.13-.36.18-.53.09-.31.13-.45a6.77,6.77,0,0,0,.17-1.58q0-.33,0-.66c0-.23,0-.47-.07-.7s-.06-.42-.1-.63-.12-.63-.2-.94l0-.12c-.06-.22-.11-.42-.18-.64-.2-.67-.42-1.33-.66-2-.09-.24-.19-.48-.29-.71s-.29-.68-.43-1c-.07-.14-.12-.27-.18-.4l-.21-.43c-.05-.1-.11-.2-.15-.3l-.42-.78a.14.14,0,0,1,.16-.2l2.64.71h0l.35.09.38.11.15,0V11a1.35,1.35,0,0,1,2.32-1,1.4,1.4,0,0,1,.4,1v2.33l.28.08.06,0,.3.22.33.27q.39.32.9.78l.25.23c.43.4.91.87,1.37,1.39l.39.45.38.47c.16.21.32.43.47.65l.22.32c.18.29.35.58.51.88.06.13.13.28.19.43a6,6,0,0,1,.41,1.19,2.56,2.56,0,0,1,.05.27v0a2.2,2.2,0,0,1,0,.37,4,4,0,0,1-.07,1.24,3.23,3.23,0,0,1-.14.51,4.5,4.5,0,0,1-.2.51,6.48,6.48,0,0,1-.53,1L31.2,25l-.26.36c-.11.14-.22.3-.34.44a4.78,4.78,0,0,1-.34.42c-.16.2-.32.38-.49.56s-.2.23-.31.34-.22.23-.32.32-.3.3-.42.41l-.27.25a.24.24,0,0,1-.14.05H26.2v2.7h2.65a2.5,2.5,0,0,0,1.61-.59,19,19,0,0,0,1.64-1.62.27.27,0,0,1,.1-.06l7.33-2.12a.23.23,0,0,1,.28.21Z"/>`,
      }}
    />
  );
};
