import styled from "styled-components";
import { Box, Link } from "rebass";
import { transitions2d } from "react-hover-css";
import Instagram from "../icons/Instagram";
import Twitter from "../icons/Twitter";
import OpenSea from "../icons/OpenSea";
import Discord from "../icons/Discord";
import Web from "../icons/Web";
import { ReactElement } from "react";

type iconSizes = "sm" | "nm" | "lg";
type colors = "light" | "dark";

type PropsSocialLinks = {
  iconSize?: iconSizes;
  instagram?: string;
  twitter?: string;
  opensea?: string;
  discord?: string;
  web?: string;
  color?: colors;
};

type PropsMenuItem = {
  color?: colors;
  href?: string;
  icon?: ReactElement;
  iconSize?: iconSizes;
  rel?: string;
  target?: string;
};

const iconSizesObject = {
  sm: 16,
  nm: 32,
  lg: 40,
};

const SocialMenu = styled(Box)`
  a:first-child {
    span {
      &,
      &.sm,
      &.nm,
      &.lg {
        margin-left: 0 !important;
      }
    }
  }
`;
const SocialIcon = styled(Box)`
  ${transitions2d.hvrBuzzOut}
  svg {
    transition: ${(props) => props.theme.animation.transitionBasic};
  }
  &.sm {
    margin-left: 10px !important;
    svg {
      width: ${iconSizesObject.sm}px;
      height: ${iconSizesObject.sm}px;
    }
  }
  &,
  &.nm {
    margin-left: 20px !important;
    svg {
      width: ${iconSizesObject.nm}px;
      height: ${iconSizesObject.nm}px;
    }
  }
  &.lg {
    margin-left: 25px !important;
    svg {
      width: ${iconSizesObject.lg}px;
      height: ${iconSizesObject.lg}px;
    }
  }
  &,
  &.dark svg {
    color: black !important;
    fill: black !important;
  }
  &.light svg {
    color: white !important;
    fill: white !important;
  }
  &.light,
  &.dark {
    &:hover {
      svg {
        color: ${(props) => props.theme.colorsInvert.primary} !important;
        fill: ${(props) => props.theme.colorsInvert.primary} !important;
      }
    }
  }
`;

const MenuItem = ({ color, href, icon, iconSize, target }: PropsMenuItem) => {
  return (
    <Link variant="nav" href={href} target={target}>
      <SocialIcon as="span" className={`${iconSize} ${color}`}>
        {icon}
      </SocialIcon>
    </Link>
  );
};

function SocialLinks({
  iconSize,
  instagram,
  twitter,
  opensea,
  web,
  color,
}: PropsSocialLinks) {
  let iconSizeDefault = iconSize ? iconSize : "nm";
  let colorDefault = color ? color : "dark";
  return (
    <SocialMenu>
      {twitter && (
        <MenuItem
          color={colorDefault}
          href={twitter}
          icon={<Twitter />}
          iconSize={iconSizeDefault}
          rel="noreferrer"
          target="_blank"
        />
      )}
      {instagram && (
        <MenuItem
          color={colorDefault}
          href={instagram}
          icon={<Instagram />}
          iconSize={iconSizeDefault}
          rel="noreferrer"
          target="_blank"
        />
      )}
      {opensea && (
        <MenuItem
          color={colorDefault}
          href={opensea}
          icon={<OpenSea />}
          iconSize={iconSizeDefault}
          rel="noreferrer"
          target="_blank"
        />
      )}
      {web && (
        <MenuItem
          color={colorDefault}
          href={web}
          icon={<Web />}
          iconSize={iconSizeDefault}
          rel="noreferrer"
          target="_blank"
        />
      )}
    </SocialMenu>
  );
}

export default SocialLinks;

// {opensea && <MenuItem color={colorDefault} href={opensea} icon={<OpenSea />} iconSize={iconSizeDefault} />}
